import cx from 'classnames'
import PropTypes from 'prop-types'

import {UIBadge} from './styles/ui.js'

const BASE_CLASS = 'op-AtomBadge'

export const Badge = ({color, size, shape, label, variant, ...props}) => {
  return (
    <UIBadge
      className={cx(BASE_CLASS, {
        [`${BASE_CLASS}--color-${color}`]: color,
        [`${BASE_CLASS}--variant-${variant}`]: variant,
        [`${BASE_CLASS}--size-${size}`]: size,
        [`${BASE_CLASS}--shape-${shape}`]: shape
      })}
      {...props}
    >
      {label}
    </UIBadge>
  )
}

Badge.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  label: PropTypes.string.isRequired,
  shape: PropTypes.oneOf(['squared', 'rounded', 'circular']),
  size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
  variant: PropTypes.oneOf(['solid', 'outline', 'flat', 'link'])
}

Badge.defaultProps = {
  color: 'primary',
  onClick: undefined,
  shape: 'squared',
  size: 'xs',
  variant: 'solid'
}
