import {DEVICE_BREAKPOINTS} from '@/styles/layout/breakpoints.js'
import styled from 'styled-components'

export const UIHistoryList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: var(--op-c-white);
  padding: calc(var(--op-spacing-base) * 8);

  li:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  @media (min-width: ${DEVICE_BREAKPOINTS.SM}) {
    border-radius: 0.5rem;
  }
`

export const UIHistoryListItem = styled.li`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--op-c-gray-light-4);
  margin-bottom: calc(var(--op-spacing-base) * 4);

  h4 {
    font-size: var(--op-fz-h5);
    line-height: var(--op-lh-l);
    margin: 0;
    font-weight: var(--op-fw-bold);
    transition: all 400ms ease-in 0s;
    margin-bottom: calc(var(--op-spacing-base) * 4);
  }

  .history-place {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span + span {
      margin-top: calc(var(--op-spacing-base) * 2);
    }
  }

  .left-side {
    margin-bottom: calc(var(--op-spacing-base) * 4);
  }

  .right-side p {
    color: var(--op-c-gray-light-1);
  }

  @media (min-width: ${DEVICE_BREAKPOINTS.SM}) {
    flex-direction: row;
    flex-wrap: nowrap;

    .left-side {
      flex: 1;
      flex-basis: 33.33%;

      .history-place {
        span + span {
          margin-left: 0;
          margin-top: calc(var(--op-spacing-base) * 2);
        }
      }
    }

    .right-side {
      flex-basis: 66.66%;
      margin-left: calc(var(--op-spacing-base) * 4);
    }
  }

  @media (min-width: ${DEVICE_BREAKPOINTS.MD}) {
    margin-bottom: calc(var(--op-spacing-base) * 6);

    h4 {
      font-size: var(--op-fz-h4);
      line-height: var(--op-lh-xl);
      margin: 0;
      font-weight: var(--op-fw-bold);
      transition: all 400ms ease-in 0s;
      margin-bottom: calc(var(--op-spacing-base) * 4);
    }

    .left-side {
      .history-place {
        flex-direction: row;
        flex-wrap: nowrap;

        span + span {
          margin-top: 0;
          margin-left: calc(var(--op-spacing-base) * 2);
        }
      }
    }

    .right-side {
      margin-bottom: calc(var(--op-spacing-base) * 4);
    }
  }
`
