export const ATOM_BADGE_COLORS = {PRIMARY: 'primary', SECONDARY: 'secondary'}
export const ATOM_BADGE_COLORS_SETTINGS = [
  {
    color: ATOM_BADGE_COLORS.PRIMARY,
    variables: {
      color: '--op-c-primary',
      colorInvert: '--op-c-white',
      colorHover: '--op-c-primary-light-2'
    }
  },
  {
    color: ATOM_BADGE_COLORS.SECONDARY,
    variables: {
      color: '--op-c-secondary',
      colorInvert: '--op-c-white',
      colorHover: '--op-c-secondary-light-2'
    }
  }
]

export const ATOM_BADGE_VARIANTS = {
  SOLID: 'solid',
  OUTLINE: 'outline',
  FLAT: 'flat',
  LINK: 'link'
}

export const ATOM_BADGE_SIZES = {
  XSMALL: 'xs',
  SMALL: 's',
  MEDIUM: 'm',
  LARGE: 'l'
}

export const ATOM_BADGE_SHAPES = {
  SQUARED: 'squared',
  ROUNDED: 'rounded',
  CIRCULAR: 'circular'
}
