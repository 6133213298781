import dfnsFormat from 'date-fns/format'
import {useCallback} from 'react'

export default function useDate() {
  const format = useCallback((date = new Date(), format = 'dd/MM/yyyy') => {
    return dfnsFormat(date, format)
  }, [])

  return {
    format
  }
}
