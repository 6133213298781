import PropTypes from 'prop-types'
import {UISection} from './styles/ui.js'

function Section({children, title, description}) {
  return (
    <UISection>
      <header>
        <h2>{title}</h2>
        <p>{description}</p>
      </header>
      {children}
    </UISection>
  )
}

export default Section

Section.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node
}

Section.defaultProps = {
  list: []
}
