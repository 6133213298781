import styled from 'styled-components'
import {
  buildAtomBadgeColors,
  buildAtomBadgeShapes,
  buildAtomBadgeSizes,
  buildAtomBadgeVariants
} from './builders.js'

export const UIBadge = styled.span`
  &.op-AtomBadge {
    --bd-atom-badge: var(--bdw-atom-badge) var(--bds-atom-badge)
      var(--bdc-atom-badge);
    --bdc-atom-badge: transparent;
    --bdrs-atom-badge: 0;
    --bds-atom-badge: solid;
    --bdw-atom-badge: 0;
    --bgc-atom-badge: var(--op-c-gray);
    --c-atom-badge: var(--op-c-white);
    --ff-atom-badge: var(--op-ff-base);
    --fw-atom-badge: var(--op-fw-bold);
    --fz-atom-badge: var(--op-fz-body);
    --lh-atom-badge: var(--op-lh-m);

    font-family: var(--ff-atom-badge);
    font-size: var(--fz-atom-badge);
    font-weight: var(--fw-atom-badge);
    line-height: var(--lh-atom-badge);
    border: var(--bd-atom-badge);
    border-radius: var(--bdrs-atom-badge);
    display: inline-block;
    color: var(--c-atom-badge);
    background-color: var(--bgc-atom-badge);

    ${buildAtomBadgeColors()}
    ${buildAtomBadgeVariants()}
    ${buildAtomBadgeShapes()}
    ${buildAtomBadgeSizes()}
  }
`
