import {css} from 'styled-components'
import {
  ATOM_BADGE_COLORS_SETTINGS,
  ATOM_BADGE_SHAPES,
  ATOM_BADGE_SIZES,
  ATOM_BADGE_VARIANTS
} from './settings.js'

export const buildAtomBadgeColors = () => {
  return ATOM_BADGE_COLORS_SETTINGS.map(({color, variables}) => {
    return css`
      &.op-AtomBadge--color-${color} {
        &.op-AtomBadge--variant-solid {
          --bgc-atom-badge: var(${variables.color});
        }

        &.op-AtomBadge--variant-outline,
        &.op-AtomBadge--variant-flat {
          --bgc-atom-badge: transparent;
          --bdc-atom-badge: var(${variables.color});
          --bdw-atom-badge: calc(var(--op-bdw-base) / 8);
          --c-atom-badge: var(${variables.color});
        }

        &.op-AtomBadge--variant-flat {
          --c-atom-badge: var(${variables.color});
        }

        &.op-AtomBadge--variant-link {
          --bd-atom-badge: 0;
          --bgc-atom-badge: transparent;
          --c-atom-badge: var(${variables.color});
          padding: 0;
          border-radius: 0;
        }
      }
    `
  })
}

export const buildAtomBadgeVariants = () => {
  return Object.values(ATOM_BADGE_VARIANTS).map(variant => {
    switch (variant) {
      case ATOM_BADGE_VARIANTS.FLAT:
        return css`
          &.op-AtomBadge--variant-${variant} {
            border-color: transparent;
          }
        `
      default:
        break
    }
  })
}

export const buildAtomBadgeShapes = () => {
  return Object.values(ATOM_BADGE_SHAPES).map(shape => {
    switch (shape) {
      case ATOM_BADGE_SHAPES.SQUARED:
        return css`
          &.op-AtomBadge--shape-${shape} {
            border-radius: 0;
          }
        `
      case ATOM_BADGE_SHAPES.ROUNDED:
        return css`
          &.op-AtomBadge--shape-${shape} {
            --bdrs-atom-badge: var(--op-bdrs-base);
          }
        `
      case ATOM_BADGE_SHAPES.CIRCULAR:
        return css`
          &.op-AtomBadge--shape-${shape} {
            --bdrs-atom-badge: calc(var(--op-bdrs-base) * 4);
          }
        `

      default:
        break
    }
  })
}

export const buildAtomBadgeSizes = () => {
  return Object.values(ATOM_BADGE_SIZES).map(size => {
    switch (size) {
      case ATOM_BADGE_SIZES.XSMALL:
        return css`
          &.op-AtomBadge--size-${size} {
            --fz-atom-badge: var(--op-fz-xs);
            --lh-atom-badge: var(--op-lh-xs);
            padding: calc(var(--op-spacing-base))
              calc(var(--op-spacing-base) * 2);
          }
        `
      case ATOM_BADGE_SIZES.SMALL:
        return css`
          &.op-AtomBadge--size-${size} {
            --fz-atom-badge: var(--op-fz-s);
            --lh-atom-badge: var(--op-lh-s);
            padding: calc(var(--op-spacing-base) * 2)
              calc(var(--op-spacing-base) * 4);
          }
        `
      case ATOM_BADGE_SIZES.MEDIUM:
        return css`
          &.op-AtomBadge--size-${size} {
            --fz-atom-badge: var(--op-fz-m);
            --lh-atom-badge: var(--op-lh-m);
            padding: calc(var(--op-spacing-base) * 3)
              calc(var(--op-spacing-base) * 7);
          }
        `
      case ATOM_BADGE_SIZES.LARGE:
        return css`
          &.op-AtomBadge--size-${size} {
            --fz-atom-badge: var(--op-fz-l);
            --lh-atom-badge: var(--op-lh-l);
            padding: calc(var(--op-spacing-base) * 4)
              calc(var(--op-spacing-base) * 8);
          }
        `

      default:
        break
    }
  })
}
