import PropTypes from 'prop-types'
import {UIHistoryListItem} from '../styles/ui.js'
import {Badge} from '@/components/ui/atom/Badge/index.js'
import useDate from '@/hooks/useDate.js'

export const HistoryListItem = ({
  title,
  subTitle,
  location,
  from,
  to,
  isCurrent = false,
  description
}) => {
  const {format} = useDate()
  return (
    <UIHistoryListItem>
      <div className="left-side">
        <h4>{title}</h4>
        <div className="history-place">
          <span>{location}</span>
          <Badge
            color="secondary"
            shape="rounded"
            label={`${format(from, 'MMM/yy')} - ${
              isCurrent ? 'Currently' : format(to, 'MMM/yy')
            }`}
          />
        </div>
      </div>
      <div className="right-side">
        <h4>{subTitle}</h4>
        <p>{description}</p>
      </div>
    </UIHistoryListItem>
  )
}

HistoryListItem.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string,
  isCurrent: PropTypes.bool,
  description: PropTypes.string,
  location: PropTypes.string
}

HistoryListItem.defaultProps = {
  to: '',
  isCurrent: false,
  description: ''
}
