import {DEVICE_BREAKPOINTS} from '@/styles/layout/breakpoints.js'
import styled from 'styled-components'

export const UISection = styled.section`
  margin-bottom: calc(var(--op-spacing-base) * 12);

  header {
    text-align: center;
    margin-bottom: calc(var(--op-spacing-base) * 8);

    h2 {
      font-size: var(--op-fz-h3);
      line-height: var(--op-lh-xxl);
      margin: 0;
      font-weight: var(--op-fw-bold);
      transition: all 400ms ease-in 0s;
      margin-bottom: calc(var(--op-spacing-base) * 4);
    }

    p {
      color: var(--op-c-gray-light-1);
    }
  }

  @media (min-width: ${DEVICE_BREAKPOINTS.SM}) {
    border-radius: 0.5rem;

    header {
      max-width: 60%;
      margin: 0 auto;
      margin-bottom: calc(var(--op-spacing-base) * 8);

      h2 {
        font-size: var(--op-fz-h2);
        line-height: var(--op-lh-xxxl);
      }
    }
  }
`
