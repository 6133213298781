import React from 'react'
import PropTypes from 'prop-types'
import {UIHistoryList} from './styles/ui.js'
import {HistoryListItem} from './components/HistoryListItem.js'

export const HistoryList = ({list}) => {
  return (
    <UIHistoryList>
      {list.map(item => (
        <HistoryListItem key={`history-list-${item.id}`} {...item} />
      ))}
    </UIHistoryList>
  )
}

HistoryList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string.isRequired,
      from: PropTypes.string.isRequired,
      to: PropTypes.string,
      isCurrent: PropTypes.bool,
      description: PropTypes.string
    })
  )
}

HistoryList.defaultProps = {
  list: []
}
